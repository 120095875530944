@keyframes city {
  from {
    -webkit-transform: translateX(-2000px);
  }

  to {
    -webkit-transform: translateX(0px);
  }
}
@keyframes myfirst {
  0% {
    -webkit-transform: translate(-50%, -50%);
  }

  50% {
    -webkit-transform: translate(-50%, calc(-50% + 20px));
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
  }
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes typedOpacity {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes permissionless-one {
  0% {
    top: 50px;
  }

  80% {
    top: 30px;
  }

  100% {
    top: 30px;
  }
}

@keyframes permissionless-three {
  0% {
    top: 128px;
  }

  80% {
    top: 108px;
  }

  100% {
    top: 108px;
  }
}

@keyframes permissionless-two {
  0% {
    top: 75px;
  }

  80% {
    top: 95px;
  }

  100% {
    top: 95px;
  }
}

@keyframes permissionless-four {
  0% {
    top: 158px;
  }

  80% {
    top: 178px;
  }

  100% {
    top: 178px;
  }
}

@keyframes programmable-one {
  0% {
    opacity: 0.3;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes programmable-three {
  0% {
    left   : 56px;
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  80% {
    left   : 6px;
    opacity: 1;
  }

  100% {
    left   : 6px;
    opacity: 1;
  }
}

@keyframes programmable-four {
  0% {
    top: 125px;
  }

  50% {
    top: 135px;
  }

  100% {
    top: 125px;
  }
}

@keyframes programmable-five {
  0% {
    right  : 72px;
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  80% {
    right  : 22px;
    opacity: 1;
  }

  100% {
    right  : 22px;
    opacity: 1;
  }
}

@keyframes composability-one {
  0% {
    top: 182px;
  }

  60% {
    top: 162px;
  }

  100% {
    top: 162px;
  }
}

@keyframes composability-two {
  0% {
    top    : 60px;
    opacity: .5;
  }

  60% {
    top    : 90px;
    opacity: 1;
  }

  100% {
    top    : 90px;
    opacity: 1;
  }
}

@keyframes composability-three {
  0% {
    top    : 30px;
    opacity: .5;
  }

  60% {
    top    : 50px;
    opacity: 1;
  }

  100% {
    top    : 50px;
    opacity: 1;
  }
}

@keyframes composability-four {
  0% {
    top    : 74px;
    opacity: .5;
  }

  60% {
    top    : 84px;
    opacity: 1;
  }

  100% {
    top    : 84px;
    opacity: 1;
  }
}

@keyframes sustainability-four {
  0% {
    left   : 182px;
    opacity: .2;
  }

  20% {
    left   : 152px;
    opacity: 1;
  }

  100% {
    left   : 152px;
    opacity: 1;
  }
}

@keyframes sustainability-three {
  0% {
    left   : -30px;
    opacity: .2;
  }

  20% {
    left   : 0;
    opacity: 1;
  }

  100% {
    left   : 0;
    opacity: 1;
  }
}

@keyframes sustainability-two {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes sustainability-one {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}