@font-face {
  font-family: 'Inter-Medium';
  src: url('../src/assets/fonts/Inter-Medium.otf') format('truetype');
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #242424;
  border-radius: 3px;
}
body {
  margin: 0;
}

html,
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
dl,
dt,
dd,
table,
tr,
td,
th,
tbody,
thead,
span,
i,
a,
input,
button,
textarea {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box !important;
  font-family: 'PingFangSC-Regular', 'SFCompactDisplay', 'Rubik-Regular', 'opensans', 'arial' ;
}

body {
  background: #030712;
  overflow: auto !important;
  width: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: inherit;
}

ul {
  list-style: none;
}

input,
button,
textarea {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.h5-box {
  display: none;
}

.pc-box {
  display: block;
}

@media screen and (max-width: 750px) {
  .pc-box {
    display: none;
  }

  .h5-box {
    display: block;
  }
}
