.footer {
  width: 100%;
  position: relative;
  background: #0a0a0a;
  .footer-container {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 80px 0 40px;
    display: flex;
    justify-content: space-between;
    > div {
      max-width: 50%;
    }
    .left {
      a {
        width: 140px;
        height: 32px;
      }
      img {
        width: 140px;
        height: 32px;
      }
    }
    .right {
      max-width: 70%;
      display: flex;
      justify-content: flex-end;
      color: #a8a8a8;
      font-size: 14px;
      padding-top: 4px;
      .left-detail,
      .social-detail {
        margin-right: 80px;
      }
      p {
        margin-bottom: 20px;
      }
      .top-foot {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 28px;
      }
      a {
        margin-bottom: 16px;
        display: block;
        color: #a8a8a8;
        &:hover {
          color: #fff;
        }
      }
      .contact-detail{
        a{
          &:hover{
            color: #a8a8a8;
          }
        }
      }
    }
  }
  .address {
    width: 1200px;
    height: 50px;
    box-shadow: inset 0 19px 10px -20px #222925;
    margin: 0 auto;
    color: #a8a8a8;
    padding: 20px 0 40px;
    font-size: 14px;
  }
}

@media screen and (max-width: 820px) {
    .footer {
      width: 100%;
      .footer-container {
        width: 100%;
        padding: 40px 16px 30px;
        flex-wrap: wrap;
        align-content: flex-start;
        >div {
          width: 51%;
        }
  
        .left {
          height: 32px;
          margin-bottom: 40px;
        }
  
        .right {
          width: 100%;
          max-width: 100%;
          flex-wrap: wrap;
          justify-content: flex-start;
          font-size: 14px;
          padding: 0;
  
          .top-foot {
            margin-bottom: 20px;
          }
          .left-detail {
            margin-right: 50px;
            margin-bottom: 20px;
          }
          .social-detail{
            margin-bottom: 20px;
          }
        }
      }
      .address {
        width: 100%;
        padding: 20px 16px 50px;
        font-size: 14px;
      }
    }
  }
@primary-color: #1DA57A;