.cetus-container {
  .cetus {
    width: 100%;
    background: #000;
    color: #fff;
    position: relative;
    padding: 80px 0 140px;
    p {
      text-align: center;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .contailer-white-paper {
      width: 1000px;
      border: 1px solid #00d49e;
      margin: 0 auto;
      color: #a8c1bc;
      font-size: 16px;
      line-height: 28px;
      padding: 40px;
      box-shadow: 0 18px 22px -8px rgba(#07ebad, 0.3) inset;
    }
  }
}

@media screen and (max-width: 820px) {
  .cetus-container {
    .cetus {
      width: 100%;
      p {
        font-size: 20px;
      }
      .contailer-white-paper {
        width: 90%;
        padding: 20px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .cetus-container {
    .cetus {
      width: 100%;
      p {
        font-size: 20px;
      }
      .contailer-white-paper {
        width: 90%;
        padding: 20px;
        font-size: 14px;
      }
    }
  }
}

@primary-color: #1DA57A;