.header_container {
  padding: 16px 0;
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  .center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    img {
      width: 140px;
      height: 32px;
    }
  }
  .social {
    display: flex;
    align-items: center;
    .item {
      margin-left: 20px;
      &:nth-child(1) {
        margin-left: 0;
      }
      .icon {
        width: 24px;
        height: 24px;
        fill: #fff;
        &:hover {
          fill: #01eeaf;
        }
      }
    }
  }
	.social-h5{
		display: none;
	}
}

@media screen and (max-width: 820px) {
  .header_container {
    width: 100%;
    display: flex;
		align-items: center;
		justify-content: space-between;
    .social {
      display: none !important;
    }

    .social-h5 {
      display: flex;
      align-items: center;
			position: relative;
			span{
				font-size: 12px;
			}
			.icon_on{
				width: 12px;
				height: 12px;
				fill: #fff;
				transition: all 0.5s;
				margin-left: 4px;
			}
			.icon-hover{
				transform: rotate(180deg);
			}
			.list-link{
				display: flex;
				flex-direction: column;
				position: absolute;
				top: 30px;
				right: 0px;
				background: #191919;
				border: 1px solid #213f37;
				padding: 0 8px 16px;
				min-width: 116px;
				a {
					display: flex;
					align-items: center;
					color: #fff;
					margin-top: 16px;
					font-size: 12px;
					.icon{
						width: 20px;
						height: 20px;
						fill: #fff;
						margin-right: 4px;
					}
				}
			}
    }
  }
}

@primary-color: #1DA57A;