.home_container {
  width: 100%;
  min-height: 100vh;
  background: #000;
  position: relative;
  color: #fff;
  min-width: 1280px;
  .center {
    width: 1200px;
    margin: auto;
  }
  .block_title {
    font-size: 36px;
    font-weight: 700;
    margin-top: 100px;
  }
  .banner {
    width: 100%;
    background-size: 100% 100%;
    color: #fff;
    position: relative;
    padding-top: 80px;
    height: 680px;
    overflow: hidden;
    .city-bg {
      position: absolute;
      top: 150px;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
      .all {
        width: 5000px;
        position: relative;
        height: 100%;
        animation: city 200s linear 0ms infinite;
      }
      .city-bg-big {
        width: 2600px;
        height: 100%;
        display: inline-block;
        position: absolute;
        top: 0;
        background: url("../image/city_bg_new.png");
        background-position: center center;
        background-size: 100% 100%;
      }
      .city-one {
        left: 0;
      }
      .city-two {
        left: 1900px;
      }
      .cetus-bg {
        width: 760px;
        height: 560px;
        position: absolute;
        top: calc(50% - 80px);
        left: calc(50% + 260px);
        transform: translate(-50%, -50%);
        z-index: 4;
        background: url("../image/cetus01.png");
        background-position: center center;
        background-size: 100% 100%;
        animation: myfirst 5s infinite linear;
      }
      .city-mask {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: url("../image/city_mask.png");
        background-position: center center;
        background-size: 100% 100%;
      }
      .city-light {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 60%;
        background-size: 100% 100%;
      }
    }
    .banner-content {
      position: relative;
      .center {
        position: absolute;
        left: 50%;
        top: 80px;
        transform: translateX(-50%);
        z-index: 99;
        .public-chain {
          width: 196px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: linear-gradient(
            48deg,
            #00ae8b,
            #00ae8b 20%,
            #0046a2
          );
          margin-bottom: 12px;
          border: 1px solid #00ffd7;
          font-size: 18px;
          .build_text {
            .typed-container {
              display: flex;
              align-items: center;
              margin-left: 12px;
              position: relative;
              img {
                width: 24px;
                height: 24px;
                animation: typedOpacity 3s linear 0ms infinite;
                margin-right: 4px;
              }
              .typed-output {
                display: inline-block;
              }
              .typed-cursor {
                display: inline-block;
                position: absolute;
                top: 0;
                right: -3px; /* 根据需要调整光标的位置 */
                animation: blink 0.7s infinite;
              }
            }
          }
        }
        .main-text {
          font-size: 88px;
          font-weight: bold;
          p {
            font-family: "Inter-Medium";
            text-indent: -4px;
            line-height: 100px;
            text-shadow: #00000040 0.1em 0.1em 0.2em;
          }
          .liquidity {
            background: linear-gradient(48deg, #32ffd5, #5194ff 80%, #5194ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: none;
          }
        }
        .main-btn {
          margin-top: 28px;
          display: flex;
          > div,
          a {
            width: 160px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            color: #000;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            &.App {
              background: linear-gradient(135deg, transparent 10px, #01eeaf 0)
                  top left,
                linear-gradient(-135deg, transparent 0, #01eeaf 0) top right,
                linear-gradient(-45deg, transparent 10px, #01eeaf 0) bottom
                  right,
                linear-gradient(45deg, transparent 0, #01eeaf 0) bottom left;
              background-size: 50% 50%;
              background-repeat: no-repeat;
              .Access {
                display: block;
              }
              .Coming {
                display: none;
              }
              &:hover {
                background: linear-gradient(135deg, transparent 10px, #00ffbd 0)
                    top left,
                  linear-gradient(-135deg, transparent 0, #00ffbd 0) top right,
                  linear-gradient(-45deg, transparent 10px, #00ffbd 0) bottom
                    right,
                  linear-gradient(45deg, transparent 0, #00ffbd 0) bottom left;
                background-size: 50% 50%;
                background-repeat: no-repeat;
                -webkit-transform: translateY(-4px);
                -webkit-transition-duration: 0.3s;
                vertical-align: top;
              }
            }
            &.Doc {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 1px;
              background: linear-gradient(135deg, transparent 10px, #01eeaf 0)
                  top left,
                linear-gradient(-135deg, transparent 0, #01eeaf 0) top right,
                linear-gradient(-45deg, transparent 10px, #01eeaf 0) bottom
                  right,
                linear-gradient(45deg, transparent 0, #01eeaf 0) bottom left;
              background-size: 50% 50%;
              background-repeat: no-repeat;
              color: #fff;
              margin-left: 24px;
              .documentation {
                width: 100%;
                height: 100%;
                background: linear-gradient(135deg, transparent 10px, #001e16 0)
                    top left,
                  linear-gradient(-135deg, transparent 0, #001e16 0) top right,
                  linear-gradient(-45deg, transparent 10px, #001e16 0) bottom
                    right,
                  linear-gradient(45deg, transparent 0, #001e16 0) bottom left;
                background-size: 50% 50%;
                background-repeat: no-repeat;
              }
              &:hover {
                background: linear-gradient(135deg, transparent 10px, #00d49e 0)
                    top left,
                  linear-gradient(-135deg, transparent 0, #00d49e 0) top right,
                  linear-gradient(-45deg, transparent 10px, #00d49e 0) bottom
                    right,
                  linear-gradient(45deg, transparent 0, #00d49e 0) bottom left;
                background-size: 50% 50%;
                background-repeat: no-repeat;
                -webkit-transform: translateY(-4px);
                -webkit-transition-duration: 0.3s;
                vertical-align: top;
                .documentation {
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(
                        135deg,
                        transparent 10px,
                        #004835 0
                      )
                      top left,
                    linear-gradient(-135deg, transparent 0, #004835 0) top right,
                    linear-gradient(-45deg, transparent 10px, #004835 0) bottom
                      right,
                    linear-gradient(45deg, transparent 0, #004835 0) bottom left;
                  background-size: 50% 50%;
                  background-repeat: no-repeat;
                }
              }
            }
          }
        }
        .banner-desc {
          font-size: 20px;
          margin-top: 16px;
          font-weight: bold;
        }
        .h5-desc {
          display: none;
        }
      }
    }
  }
  .advantages {
    .advantages_content {
      margin-top: 36px;
      > div {
        margin-top: 48px;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      .advantages_item {
        height: 360px;
        display: flex;
        justify-content: space-between;
        position: relative;
        border: 2px solid;
        border-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0)
          )
          2 2;

        .text-box {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          align-content: center;
          padding: 0 48px;
          width: 790px;

          p {
            width: 100%;
            margin: 0 0 28px 0;
            font-size: 32px;
            line-height: 1;
            color: #fff;
          }

          span {
            font-size: 18px;
            color: rgba(#fff, 0.6);
          }
        }

        .animation-box {
          width: 400px;
          height: 360px;
          position: relative;

          .permissionless-one {
            width: 165px;
            height: 208px;
            position: absolute;
            top: 70px;
            left: 80px;
            background: url("../image/permissionless-one.png");
            background-size: 100% 100%;
            z-index: 4;
            animation: permissionless-one 2s linear 0ms infinite alternate;
          }

          .permissionless-two {
            width: 160px;
            height: 204px;
            position: absolute;
            top: 95px;
            left: 120px;
            background: url("../image/permissionless-two.png");
            background-size: 100% 100%;
            z-index: 3;
            animation: permissionless-two 2s linear 0ms infinite alternate;
          }

          .permissionless-three {
            width: 180px;
            height: 206px;
            position: absolute;
            top: 128px;
            left: 144px;
            background: url("../image/permissionless-three.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: permissionless-three 2s linear 0ms infinite alternate;
          }

          .permissionless-four {
            width: 176px;
            height: 192px;
            position: absolute;
            top: 178px;
            left: 164px;
            background: url("../image/permissionless-four.png");
            background-size: 100% 100%;
            z-index: 1;
            animation: permissionless-four 2s linear 0ms infinite alternate;
          }

          .permissionless-five {
            width: 400px;
            height: 296px;
            position: absolute;
            top: 30px;
            left: 0px;
            background: url("../image/permissionless-five.png");
            background-size: 100% 100%;
            z-index: 1;
            animation: programmable-one 2s linear 0ms infinite alternate;
          }

          .programmable-one {
            width: 266px;
            height: 192px;
            position: absolute;
            top: 88px;
            left: 60px;
            background: url("../image/programmable-one.png");
            background-size: 100% 100%;
            z-index: 1;
            animation: programmable-one 2s linear 0ms infinite alternate;
          }

          .programmable-two {
            width: 326px;
            height: 252px;
            position: absolute;
            top: 72px;
            left: 30px;
            background: url("../image/programmable-two.png");
            background-size: 100% 100%;
            z-index: 2;
          }

          .programmable-three {
            width: 110px;
            height: 132px;
            position: absolute;
            top: 164px;
            left: 6px;
            background: url("../image/programmable-three.png");
            background-size: 100% 100%;
            z-index: 3;
            animation: programmable-three 2s linear 0ms infinite alternate;
          }

          .programmable-four {
            width: 136px;
            height: 136px;
            position: absolute;
            top: 150px;
            left: 126px;
            background: url("../image/programmable-four.png");
            background-size: 100% 100%;
            z-index: 3;
            animation: programmable-four 2s linear 0ms infinite alternate;
          }

          .programmable-five {
            width: 110px;
            height: 132px;
            position: absolute;
            top: 164px;
            right: 22px;
            background: url("../image/programmable-five.png");
            background-size: 100% 100%;
            z-index: 3;
            animation: programmable-five 2s linear 0ms infinite alternate;
          }

          .composability-one {
            width: 140px;
            height: 148px;
            position: absolute;
            top: 182px;
            left: 60px;
            background: url("../image/composability-one.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: composability-one 2s linear 0ms infinite alternate;
          }

          .composability-two {
            width: 112px;
            height: 118px;
            position: absolute;
            top: 110px;
            left: 60px;
            background: url("../image/composability-two.png");
            background-size: 100% 100%;
            animation: composability-two 2s linear 0ms infinite alternate;
          }

          .composability-three {
            width: 140px;
            height: 146px;
            position: absolute;
            top: 70px;
            left: 130px;
            background: url("../image/composability-three.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: composability-three 2s linear 0ms infinite alternate;
          }

          .composability-four {
            width: 140px;
            height: 148px;
            position: absolute;
            top: 104px;
            left: 200px;
            background: url("../image/composability-four.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: composability-four 2s linear 0ms infinite alternate;
          }

          .composability-five {
            width: 140px;
            height: 148px;
            position: absolute;
            top: 182px;
            left: 200px;
            background: url("../image/composability-five.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: composability-one 2s linear 0ms infinite alternate;
          }

          .composability-six {
            width: 170px;
            height: 170px;
            position: absolute;
            top: 160px;
            left: 130px;
            background: url("../image/composability-six.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: programmable-four 2s linear 0ms infinite alternate;
          }

          .composability-seven {
            width: 376px;
            height: 291px;
            position: absolute;
            top: 36px;
            left: 12px;
            background: url("../image/composability-seven.png");
            background-size: 100% 100%;
            z-index: 1;
            animation: programmable-one 2s linear 0ms infinite alternate;
          }

          .composability-eight {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 162px;
            left: 226px;
            background: url("../image/composability-eight.png");
            background-size: 100% 100%;
            z-index: 2;
          }

          .sustainability-one {
            width: 226px;
            height: 304px;
            position: absolute;
            top: 10px;
            left: 154px;
            background: url("../image/sustainability-one.png");
            background-size: 100% 100%;
            z-index: 1;
            animation: sustainability-one 4s 0ms 1 linear;
            animation-iteration-count: infinite;
          }

          .sustainability-two {
            width: 162px;
            height: 216px;
            position: absolute;
            top: 80px;
            left: 12px;
            background: url("../image/sustainability-two.png");
            background-size: 100% 100%;
            z-index: 1;
            animation: sustainability-two 4s 0ms 1 linear;
            animation-iteration-count: infinite;
          }

          .sustainability-three {
            width: 266px;
            height: 266px;
            position: absolute;
            top: 80px;
            left: 0;
            background: url("../image/sustainability-three.png");
            background-size: 100% 100%;
            z-index: 3;
            animation: sustainability-three 4s 0ms 1 linear;
            animation-iteration-count: infinite;
          }

          .sustainability-four {
            width: 208px;
            height: 214px;
            position: absolute;
            top: 70px;
            left: 152px;
            background: url("../image/sustainability-four.png");
            background-size: 100% 100%;
            z-index: 2;
            animation: sustainability-four 4s 0ms 1 linear;
            animation-iteration-count: infinite;
          }
        }

        &:nth-child(1) {
          margin-top: 0px;
        }
      }
    }
  }
  .auditedBy {
    .auditedBy_content {
      margin-top: 36px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 100%;
        &:nth-child(1) {
          .auditedBy_item {
            margin-left: 0;
          }
        }
      }
      .auditedBy_item {
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
      }
      .OtterSec-mask {
        background: linear-gradient(
          180deg,
          rgba(0, 255, 186, 0.22) 0%,
          rgba(17, 247, 214, 0) 100%
        );
      }
      .MoveBit-mask {
        background: linear-gradient(
          180deg,
          rgba(0, 174, 255, 0.29) 0%,
          rgba(17, 247, 214, 0) 100%
        );
      }
      a {
        width: 200px;
        height: 44px;
        display: block;
      }
      .OtterSec {
        background: url("../image/OtterSec-logo.png");
        background-size: 100% 100%;
      }
      .MoveBit {
        background: url("../image/logo-movebit.png");
        background-size: 100% 100%;
      }
    }
  }
  .investors {
    .block_title {
      margin-top: 140px;
    }
    .content {
      margin-top: 36px;
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        img {
          width: 374px;
          height: 80px;
        }
        &:nth-child(1) {
          img {
            width: 590px;
            height: 80px;
          }
        }
      }
    }
    .h5-content {
      display: none;
    }
  }
  .aquarium {
    margin-top: 140px;
    .center {
      position: relative;
      background: url("../image/banner_cetusaquarium@2x.png") 50% no-repeat;
      background-size: cover;
      height: 480px;
      padding: 50px;
      padding-left: 0;
    }
    .content {
      display: flex;
      .left {
        width: 38%;
        position: relative;
        img {
          width: 95%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-left: -12px;
          margin-top: -8px;
        }
      }
      .right {
        width: 62%;
        .block_title {
          margin-top: 0;
          text-align: left;
        }
        .power {
          display: flex;
          align-items: center;
          padding-left: 12px;
          width: 175px;
          height: 32px;
          background: linear-gradient(
            270deg,
            rgba(17, 18, 20, 0.2) 0%,
            rgba(#76c8ff, 0.2) 100%
          );
          margin-bottom: 20px;
          .power-text {
            font-weight: 500;
            font-size: 14px;
            color: #4da2ff;
            margin-right: 8px;
          }
          img {
            height: 20px;
          }
        }
        .title {
          margin-bottom: 8px;
        }
        .desc {
          font-size: 24px;
          color: #07ebad;
        }
        .text {
          margin-top: 30px;
          font-size: 18px;
          color: #b0b0b0;
        }
        .main-btn {
          margin-top: 40px;
          display: flex;

          > div,
          a {
            width: 160px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            color: #000;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            position: relative;

            &.App {
              background: linear-gradient(135deg, transparent 10px, #01eeaf 0)
                  top left,
                linear-gradient(-135deg, transparent 0, #01eeaf 0) top right,
                linear-gradient(-45deg, transparent 10px, #01eeaf 0) bottom
                  right,
                linear-gradient(45deg, transparent 0, #01eeaf 0) bottom left;
              background-size: 50% 50%;
              background-repeat: no-repeat;

              .Access {
                display: block;
              }

              &:hover {
                background: linear-gradient(135deg, transparent 10px, #00ffbd 0)
                    top left,
                  linear-gradient(-135deg, transparent 0, #00ffbd 0) top right,
                  linear-gradient(-45deg, transparent 10px, #00ffbd 0) bottom
                    right,
                  linear-gradient(45deg, transparent 0, #00ffbd 0) bottom left;
                background-size: 50% 50%;
                background-repeat: no-repeat;
                -webkit-transform: translateY(-4px);
                -webkit-transition-duration: 0.3s;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }
  .focus-on-move {
    width: 100%;
    background: url("../image/bg-Focus-on-Move.png");
    background-size: 100% 100%;
    position: relative;
    z-index: 30;
    margin-top: 140px;
    .block_title {
      margin: 0 0 40px;
    }
    .focus-main {
      height: 100%;
      margin: auto;
      padding: 92px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .focus-item {
        width: 100%;
        display: flex;
        height: 190px;
        .content {
          .content-tit {
            width: 100%;
            font-size: 24px;
            padding: 0 20px 0 0;
            color: #fff;

            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;

            .right {
              width: 100px;
              height: 20px;
              display: flex;
              justify-content: space-between;

              > a {
                width: 20px;
                height: 20px;
                cursor: pointer;
              }

              .webiste {
                background: url("../image/icon-Webiste.png");
                background-size: 100% 100%;

                &:hover {
                  background: url("../image/icon-Webiste-hover-sui.png");
                  background-size: 100% 100%;
                }
              }

              .twitter {
                background: url("../image/icon-twitter-hover.png");
                background-size: 100% 100%;

                &:hover {
                  background: url("../image/icon-Twitter-hover-sui.png");
                  background-size: 100% 100%;
                }
              }
              .github {
                background: url("../image/icon-Github-hover.png");
                background-size: 100% 100%;

                &:hover {
                  background: url("../image/icon-Github-hover-sui.png");
                  background-size: 100% 100%;
                }
              }
            }
          }
          .content-detail {
            color: #a1c4da;
            margin-top: 12px;
            font-size: 16px;
            line-height: 28px;
          }
        }
      }

      .focus-aptos {
        margin-top: 60px;
        .content {
          margin-left: 40px;
          .content-tit {
            .right {
              .webiste {
                &:hover {
                  background: url("../image/icon-Webiste-hover-aptos.png");
                  background-size: 100% 100%;
                }
              }

              .twitter {
                &:hover {
                  background: url("../image/icon-Twitter-hover-aptos.png");
                  background-size: 100% 100%;
                }
              }

              .github {
                &:hover {
                  background: url("../image/icon-Github-hover-aptos.png");
                  background-size: 100% 100%;
                }
              }
            }
          }
          .content-detail {
            color: #b7d9cf;
          }
        }
      }
      .focus-sui {
        .content {
          margin-right: 40px;
        }
      }
      .public-h5 {
        display: none;
      }
      .public {
        width: 460px;
        height: 190px;
      }
    }
  }
  .powered-by {
    margin-top: 80px;
    .center {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .left {
      width: 460px;
      height: 140px;
      background: #151717;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .top {
        font-size: 20px;
        width: 304px;
        margin-bottom: 8px;
      }
      img {
        width: 304px;
        height: 40px;
      }
    }
    .right {
      max-width: 700px;
      .text {
        line-height: 28px;
        font-weight: 400;
        font-size: 16px;
        color: #b0b0b0;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        a {
          color: #76c8ff;
        }
      }
    }
  }
  .faq {
    position: relative;
    padding-bottom: 120px;
    .block_title {
      margin-top: 80px;
      margin-bottom: 36px;
    }
    .botBto {
      margin: 0 auto;
      position: relative;
      z-index: 5;
      .top-botBto {
        font-family: "Helvetica-NeueBd";
        height: 64px;
        margin: 0 auto;
        font-size: 20px;
        margin-bottom: 20px;
        padding: 0 40px 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        span {
          white-space: nowrap;
        }
        p {
          width: 100%;
          height: 1px;
          margin: 0 20px;
          border-top: 1px dashed #4d4d4d;
        }
        div {
          width: 28px !important;
          height: 28px !important;
          flex-shrink: 0;
          background-image: url("../image/icon-an@2x.png");
          background-size: 100% 100%;
          &:hover {
            background-image: url("../image/icon-an-hover@2x.png");
          }
        }
        .change {
          background-image: url("../image/icon-Pack-up@2x.png");
          &:hover {
            background-image: url("../image/icon-Pack-up-hover@2x.png");
          }
        }
      }

      .bot-botBto {
        padding: 0 40px 40px;
        margin-bottom: 20px;

        span {
          line-height: 28px;
          font-size: 16px;
          color: #ffffffa1;
        }
      }
    }

    .chosen {
      background: linear-gradient(360deg, rgba(0, 17, 14, 0) 0%, #073025 100%);
      .top-botBto {
        background: none;
        border: none;
      }
    }
  }
  .footer-bg-box {
    position: absolute;
    width: 100%;
    height: 380px;
    bottom: 0;
    left: 0;
    background: url("../image/dark-blue-galaxy-space-with-stars-well-use-as-astronomy-backgrou@2x.png");
    background-size: 100% 100%;
    z-index: 1;
  }
}
@media screen and (max-width: 1390px) {
  .home_container {
    .faq {
      .faq-bg {
        width: 1190px !important;
      }
      .faq-bg-two {
        left: 50% !important;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .home_container {
    width: 100%;
    min-width: 100%;
    overflow: hidden;
    .center {
      width: 100%;
      padding: 0 16px;
    }
    .block_title {
      font-size: 24px;
      text-align: center;
      margin-top: 60px;
    }
    .banner {
      padding-top: 20px;
      .city-bg {
        height: 500px;
        .cetus-bg {
          width: 342px;
          height: 252px;
          left: 50%;
          top: calc(50% + 42px);
        }

        .city-light {
          top: 268px;
          height: 30%;
        }
      }
      .banner-content {
        .center {
          .public-chain {
            margin: 0 auto 20px;
          }
          .main-text {
            font-size: 48px;
            font-weight: bold;
            p {
              line-height: 58px;
              text-align: center;
            }
          }
          .main-btn {
            margin-top: 20px;
            justify-content: center;
          }
          .banner-desc {
            font-size: 16px;
            padding: 8px 28px 0;
            text-align: center;
            font-family: "";
          }
          .pc-desc {
            display: none;
          }
          .h5-desc {
            padding-top: 200px;
            display: block;
          }
        }
      }
    }
    .advantages {
      .advantages_content {
        margin-top: 36px;
        .advantages_item {
          width: 100%;
          height: auto;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          .text-box {
            width: 100%;
            padding: 24px 16px 0;
            p {
              font-size: 24px;
            }
            span {
              font-size: 16px;
            }
          }
          .animation-box {
            width: 354px !important;
            margin-left: -70px;
            .programmable-three{
              margin-left: 20px;
            }
            .programmable-five{
              margin-right: -30px;
            }
          }
        }
        .permissionless,
        .composability {
          flex-direction: column-reverse;
          align-items: center;
          justify-content: center;
        }
        .programmable {
          .animation-box {
            margin-left: -30px;
          }
        }
      }
    }
    .auditedBy {
      .auditedBy_content {
        padding: 0 16px;
        margin-top: 20px;
        .auditedBy_item {
          width: 100%;
          height: 60px;
        }
        a {
          width: 120px;
          height: 28px;
        }
      }
    }
    .investors {
      .content {
        display: none;
      }
      .h5-content {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        img {
          width: 48%;
          height: auto;
          margin-bottom: 12px;
        }
      }
    }
    .aquarium {
      background: none;
      height: auto;
      margin-top: 90px;
      padding: 0 20px;
      .center {
        padding: 0;
        height: unset;
      }
      .content {
        background: linear-gradient(180deg, #111214 0%, #08090a 100%);
        flex-direction: column-reverse;
        padding: 20px 8px;
        padding-bottom: 0;
        .left {
          width: 100%;
          max-width: 375px;
          overflow: hidden;
          height: 400px;
          margin: auto;
          img {
            width: 100%;
          }
        }
        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .power {
            margin-bottom: 12px;
          }
          .title {
            margin-bottom: 0px;
          }
          .text {
            margin-top: 12px;
          }
          .main-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .focus-on-move {
      margin-top: 60px;
      background: none;
      .focus-main {
        padding: 0;
        width: 100%;
        >div{
          width: 100%;
        }
        .focus-item {
          width: 100%;
          height: unset;
          display: flex;
          flex-direction: column;
          .public {
            margin-top: 20px;
            width: 100%;
            height: auto;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .focus-sui {
          .content {
            margin-right: 0;
          }
        }
        .focus-aptos {
          flex-direction: column-reverse;
          .content {
            margin-left: 0;
          }
        }
      }
    }
    .powered-by {
      margin-top: 100px;
      .center {
        display: flex;
        flex-direction: column;
        padding: 0 16px 40px;
        > div {
          width: 100%;
        }
        .left {
          width: 100%;
          height: 92px;
          margin-bottom: 20px;
          .top {
            width: 180px;
          }
          img {
            width: 180px;
            height: unset;
          }
        }
        .right {
          max-width: 100%;
          .text {
            font-size: 16px;
          }
        }
      }
    }
    .faq {
      width: 100% !important;
      padding: 0px 0 40px;
      .title {
        padding: 0;
      }
      .botBto {
        .top-botBto {
          padding: 14px 20px 12px;
          width: 100%;
          font-size: 16px;
          height: 60px;
          margin-bottom: 0px;

          p {
            display: none;
          }
          span {
            padding-right: 20px;
            font-size: 16px;
            line-height: 20px;
            white-space: normal;
          }
          div {
            &:hover {
              background-image: url("../image/icon-an@2x.png");
            }
          }
          .change {
            &:hover {
              background-image: url("../image/icon-Pack-up@2x.png");
            }
          }
        }
        .bot-botBto {
          width: 100%;
          padding: 8px 20px 20px;
          margin-bottom: 12px;
          span {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .faq-bg {
        display: none;
      }
      .faq-bg-two {
        display: none;
      }
      .footer-bg-box {
        height: 90px;
      }
    }
  }
}

@primary-color: #1DA57A;